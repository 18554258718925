<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" class="float-right" top right @click="$router.go(-1)">Zurück</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="10" lg="6">
            <div class="card">
              <div class="card-header card-header-primary">
                <div class="card-title">Anlageninfomation</div>
              </div>
              <div class="card-body">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <b>Anlagenbezeichnung</b>
                        </td>
                        <td>{{ anlage.name }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Gebäude</b>
                        </td>
                        <td>{{ anlage.gebaeude }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Standort</b>
                        </td>
                        <td>{{ anlage.standort }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Typ</b>
                        </td>
                        <td>{{ anlage.typ }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Seriennummer</b>
                        </td>
                        <td>{{ anlage.seriennummer }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Baujahr</b>
                        </td>
                        <td>{{ anlage.baujahr }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="10">
            <div class="card-title">Warungshistorie</div>

            <div v-if="history.length > 0">
              <v-expansion-panels multiple focusable>
                <v-expansion-panel v-for="(history,i) in history" :key="i">
                  <v-expansion-panel-header>Durchgeführt am: {{$formatDate(history.created)}}, {{history.username}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="card my-4">
                      <div class="card-body">
                        <v-data-table
                          :headers="headers"
                          :items="JSON.parse(history.items)"
                          hide-default-footer
                          :items-per-page="-1"
                          class="elevation-1"
                        >
                          <template v-slot:item.name="{ item }">
                            <b>{{item.name }} / {{item.typ}}</b>
                            ({{item.number}} {{item.title}})
                          </template>
                          <template v-slot:item.status="{ item }">
                            <div class="text-center">
                              <i
                                style="color:green"
                                v-if="item.status === 1"
                                class="material-icons"
                              >check_circle</i>
                              <i v-else style="color:red" class="material-icons">remove_circle</i>
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <v-btn @click="showWartung(item)" color="primary" small fab>
                              <i class="material-icons">playlist_add_check</i>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else>Für diese Anlage wurde bisher keine Wartung durchgeführt.</div>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Bauteil Prüfung</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <b>{{newItem.name }} / {{newItem.typ}}</b>
                ({{newItem.number}} {{newItem.title}})
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-data-table
                    :headers="headersbauteile"
                    :items="newItem.items"
                    hide-default-footer
                    :items-per-page="-1"
                    class="elevation-1"
                  >
                    <template v-slot:item.number="{ item }">
                      <span :class="{'bold':item.required}">{{item.number}}</span>
                    </template>
                    <template v-slot:item.title="{ item }">
                      <span :class="{'bold':item.required}">{{item.title}}</span>
                      <div v-if="item.measurement && item.measurement !== '-'">
                        <small v-if="item.measurement === '1'">Messung erforderlich (Soll/Ist-Wert)</small>
                        <small v-else>Messung erforderlich (Ist-Wert)</small>
                      </div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div v-if="item.required">
                        <span class="bold">X</span>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:item.measurements="{ item }">
                      <div v-if="item.measurement && item.measurement !== '-'">
                        <div v-if="item.measurement === '2'">{{item.measuredvalue}}</div>
                        <div v-else>
                          <span>
                            <b>Soll-Wert:</b>
                          </span>
                          <span>{{item.sollvalue}}</span>
                          <br />
                          <span>
                            <b>Ist-Wert:</b>
                          </span>
                          <span>{{item.istvalue}}</span>
                          <br />
                        </div>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:item.done="{ item }">
                      <div>
                        <i
                          style="color:green"
                          v-if="item.done === true"
                          class="material-icons"
                        >check_circle</i>
                        <i style="color:red" v-else class="material-icons">remove_circle</i>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h4 class="mb-4 mt-4">Mängel</h4>
                  <div v-if="newItem.images && newItem.images.length > 0">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      v-for="(image) in newItem.images"
                      :key="image"
                    >
                      <v-img :src="getImage(image)" contain></v-img>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <div>
                    <b>Bemerkung Kunde:</b>
                  </div>
                  <div>{{newItem.comment}}</div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <div>
                    <b>Bemerkung itern:</b>
                  </div>
                  <div>{{newItem.commentintern}}</div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      wartung: {},
      anlage: {},
      status: 0,
      ended: false,
      anlagen: [],
      images: [],
      requiredMeasurement: [],
      requiredTasks: [],
      history: [],
      newItem: {
        id: "",
        name: "",
        typ: "",
        title: "",
        number: "",
        hasMangel: false,
        commentintern: "",
        comment: "",
        images: "",
        items: []
      },
      headersbauteile: [
        {
          text: "Nummer",
          align: "left",
          sortable: false,
          value: "number"
        },
        {
          text: "Aufgabe",
          align: "left",
          sortable: false,
          value: "title"
        },
        {
          text: "Erforderlich",
          align: "center",
          value: "required",
          sortable: false
        },
        {
          text: "Messungen",
          value: "measurements",
          sortable: false
        },
        {
          text: "Sonstiges",
          value: "other",
          sortable: false
        },
        {
          text: "Erledigt",
          value: "done",
          sortable: false
        }
      ],
      newAnlage: {
        name: "",
        gebaeude: ""
      },
      headers: [
        {
          text: "Bezeichnung/Typ",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Kommentar Kunden",
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: "Kommentar Intern",
          align: "left",
          sortable: false,
          value: "commentintern"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    role() {
      return this.$store.getters.user.role;
    }
  },
  mounted: function() {
    this.getAnlage();
    this.getHistoricWartungen();
  },
  methods: {
    getAnlage() {
      let formData = new FormData();
      formData.append("id", this.id);
      var self = this;
      this.$http({
        method: "post",
        url: "getAnlage.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            self.anlage = response.data.data;
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getHistoricWartungen() {
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getHistoricWartungen.php",
        data: formData
      })
        .then(response => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then(resp => {
                this.$router.push("/login");
              })
              .catch(err => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.data) {
            this.history = response.data.data;
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getImage(img) {
      return this.$http.defaults.serverURL + "/uploads/" + img;
    },
    showWartung(item) {
      this.newItem = item;
      this.dialog = true;
    }
  }
};
</script>
